<template>
  <b-card-body body-border-variant="danger" class="pt-0">
    <!-- <b-row no-gutters>
      <b-col sm ="8"> -->
        <b-input-group prepend="Company" v-if="hasCompany">
          <b-form-input disabled id="company" type="text" v-model="selectedCompany.name"></b-form-input>
          <b-input-group-append>
            <b-button class="pull-right mr-1" :variant="isSelectCompany? 'secondary' : 'primary'" size="md" @click="  onSelectCompany">Select</b-button>
          </b-input-group-append>
        </b-input-group>
        <b-input-group prepend="Company" v-else>
          <b-form-input disabled id="company" type="text" v-model="selectCompany"></b-form-input>
          <b-input-group-append>
            <b-button class="pull-right mr-1" :variant="isSelectCompany? 'secondary' : 'primary'" size="md" @click="  onSelectCompany">Select</b-button>
          </b-input-group-append>
        </b-input-group>
      <!-- </b-col>
    </b-row> -->
    <CompanySelect v-if="showCompanyComponent" @company="onCompanySelected"/>
  </b-card-body>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import CompanySelect from './CompanySelect'

export default AdminExtension.extend({
    name: 'Company',
    props: {
      company: {
        require: false,
        type: Object,
      }
    },
    components: {
      CompanySelect,
    },
    computed:{
      showCompanyComponent(){
        return this.isSelectCompany
      },
      hasCompany(){
        if(this.company){
          this.selectedCompany = this.company
          return true
        }else if (this.selectedCompany){
          return true
        }else {
          return false
        }
      } 
    },
    data() {
        return {
          id: null,
          isEdit: false,
          isSelectCompany: false,
          selectedCompany: null,
          selectCompany: null,
        }
    },
    methods: {
        onReset(){
          this.isSelectCompany = false
          this.selectedCompany = null
        },
        onCompanySelected(company){
          this.$emit('company', company)
          this.selectedCompany = company
          this.isSelectCompany = false
        },
        onSelectCompany(){
          this.isSelectCompany = !this.isSelectCompany

        },
    },
})
</script>
