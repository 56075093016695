<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row>
      <b-col sm="12">
        <b-form @submit.stop.prevent="onSubmit" @keyup="onChange" :novalidate="true">
          <b-card no-body class="shadow-lg rounded" border-variant="primary">
            <div slot="header">
              <strong>Client Form</strong>
              <!-- <b-button class=" pull-right" @click="$router.go(-1)" size="sm" variant="primary"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Back</b-button> -->
            </div>
                <b-card-body>
                  <b-form-group
                    label="Code"
                    label-for="ClientCode"
                    :label-cols="2"
                    :invalid-feedback="form.errors.get('code')"
                    :state="form.errors.has('code')"
                    >
                    <b-form-input disabled id="ClientCode" type="text" autocomplete="name" placeholder="Client code"v-model="form.code"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    :label="isEdit? 'Username': 'Username *'"
                    label-for="username"
                    :label-cols="2"
                    :invalid-feedback="form.errors.get('username')"
                    :state="form.errors.has('username')" 
                    >
                    <b-form-input id="username" type="text" autocomplete="username" placeholder="Username" v-model="form.username"></b-form-input>
                  </b-form-group><b-form-group
                    :label="isEdit? 'Email': 'Email *'"
                    label-for="email"
                    :label-cols="2"
                    :invalid-feedback="form.errors.get('email')"
                    :state="form.errors.has('email')" 
                    >
                    <b-form-input id="email" type="email" autocomplete="email" placeholder="Email" v-model="form.email"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    :label="isEdit? 'Password' : 'Password *' "
                    label-for="password"
                    :label-cols="2"
                    :invalid-feedback="form.errors.get('password')"
                    :state="form.errors.has('password')" 
                    >
                    <b-form-input id="password" autocomplete="password" placeholder="Password" v-model="form.password" type="password" :debounce="800"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    :label="isEdit? 'Retype Password' : 'Retype Password *' "
                    label-for="confirmPassword"
                    :label-cols="2"
                    invalid-feedback="Password does not match"
                    :state="passwordValidation" 
                    >
                    <b-form-input id="confirmPassword" placeholder="Retype Password" v-model="confirmPassword" type="password"></b-form-input>
                  </b-form-group>
                  <b-form-group
                    :label="isEdit? 'Fullname': 'Fullname *'"
                    label-for="fullname"
                    :label-cols="2"
                    :invalid-feedback="form.errors.get('fullname')"
                    :state="form.errors.has('fullname')" 
                    >
                    <b-form-input id="fullname" type="text" autocomplete="fullname" placeholder="Fullname" v-model="form.fullname"></b-form-input>
                  </b-form-group>
                </b-card-body>
                  <Company ref="company" :company ="clientCompany" @company="onSelectedCompany"></Company>
                  <Settings :settings="form.datasetting" @select="onDataSettings"/>
            <b-card-footer>
              <b-button v-if="form.id" size="sm" variant="danger" v-b-modal="'confirm'+form.id"><i class="fa fa-trash" aria-hidden="true"></i> Verwijderen</b-button>
              <b-modal 
                :id="'confirm'+form.id" 
                size="sm"
                :centered="true"
                button-size="lg"
                :hide-header="true"
                cancel-title="No"
                ok-title="Yes"
                auto-focus-button="cancel"
                @ok="removeClient"
                >
                Are you sure you want to remove this client?
              </b-modal>
              <b-button v-if="isChange" :disabled="!form.company" class=" pull-right" type="submit" size="sm" variant="success"><i class="fa fa-check-circle-o" aria-hidden="true"></i> {{form.id? 'Aanpassen':'Opslaan'}}</b-button>
              <!-- <b-button class="pull-right mr-2" @click="$router.go(-1)" size="sm" variant="warning"><i class="fa fa-backward"></i> Terug</b-button> -->
              <b-button class="pull-right mr-2" @click="onResetClient" size="sm" variant="warning"><i class="fa fa-backward"></i> Reset</b-button>
            </b-card-footer>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import Company from '../company/Company'
import Settings from '@/components/clients/Settings'

export default AdminExtension.extend({
    name: 'ClientModify',
    props: {
        client: {
            type: Object,
            require: false,
        }
    },
    components: {
      Settings,
      Company,
    },
    data() {
        return {
            isLocked: false,
            isSaved: true,
            form: new Form({
                    id: null,
                    consistency_token: null,
                    code: null,
                    email: null,
                    username: null,
                    password: null,
                    fullname: null,
                    company: null,
                    datasetting: null,
                }),
            isEdit: false,
            isChange: false,
            confirmPassword: null,
            company: null,
        }
    },
    computed: {
      passwordValidation(){
        return this.form.password == this.confirmPassword
      },
      clientCompany(){
        if (this.client){
          if (this.client.company){
            return this.client.company
          }
        }
        return null
      },
    },
    methods: {
        ...mapActions("meds2go/client", ["clientCreate","clientUpdate","clientRemove"]),
        ...mapActions("common", ["generateCode"]),
        loadData(){
            if (this.client){
                this.onReset()
                this.form = new Form(this.client)
                if (this.form.company){
                  this.form.company = this.form.company.id
                }
                this.isEdit = true
            }else{
              this.generateCode({generate_code:'client'}).then(response=> {
                this.form.code = response
              })
            }
        },
        onSubmit() {
            if(this.isEdit){
              // console.log(this.form.data())
                this.clientUpdate(this.form.data()).then(response => {
                    this.$router.push(`/dashboard/client/overview`)
                }).catch(errors => {
                    this.form.errors.record(errors)
              })
            }   
            else {
                this.clientCreate(this.form.data()).then(response => {
                    this.form.reset()
                    this.$router.push(`/dashboard/client/overview`)
                }).catch(errors => {
                    this.form.errors.record(errors)
                })

            }
        },
        onReset(){
            this.form.reset(['code'])
            this.confirmPassword = null
            this.isChange = false
        },
        onChange(){
            this.isChange = true
        },
        removeClient(){
            this.clientRemove({'id': this.form.id, 'consistency_token': this.form.consistency_token, 'status': false}).
              then(this.$router.push("/dashboard/client/overview")).catch(errors => {
                    this.form.errors.record(errors)
            })
        },
        onSelectedCompany(company){
          this.company = company
          this.form.company = company.id
          this.isChange = true
        },
        onResetClient(){
          this.$refs.company.onReset()
          this.onReset()
        },
        onDataSettings(data){
          this.form.datasetting = data
          this.onChange()
        }
    },
    mounted() {
        this.loadData()
    }
})
</script>

