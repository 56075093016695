<template>
	<b-card-body body-border-variant="danger" class="p-0">
		<b-card text-variant="dark" border-variant="white" class="mx-0">
			<b-card-header class="p-0 mx-0 mt-0 mb-2" header-bg-variant="white">
				<span> Settings <small><strong> (*Client settings take precedence over Device settings)</strong></small></span>
			</b-card-header>
			<b-row no-gutters>
				<b-col>
					<b-form-checkbox @input="onSelect" switch id="navigation" v-model="settingsForm.navigation" name="navigation">
						Navigation
					</b-form-checkbox>
				</b-col>
				<b-col>
					<b-form-checkbox @input="onSelect" switch id="temperature1" v-model="settingsForm.temperature1" name="temperature1">
						Temperature 1
					</b-form-checkbox>
					<b-form-checkbox @input="onSelect" switch id="temperature2" v-model="settingsForm.temperature2" name="temperature2">
						Temperature 2
					</b-form-checkbox>
					<b-form-checkbox @input="onSelect" switch id="temperature3" v-model="settingsForm.temperature3" name="temperature3">
						Temperature 3
					</b-form-checkbox>
				</b-col>
				<b-col>
					<b-form-checkbox @input="onSelect" switch id="lock" v-model="settingsForm.lock" name="lock">
						Lock Status
					</b-form-checkbox>
					<b-form-checkbox @input="onSelect" switch id="location_lock" v-model="settingsForm.location_lock" name="location_lock">
						Lock Location
					</b-form-checkbox>
				</b-col>
				<b-col>
					<b-form-checkbox @input="onSelect" switch id="orientation" v-model="settingsForm.orientation" name="orientation">
						Orientation Status
					</b-form-checkbox>
					<b-form-checkbox @input="onSelect" switch id="location_orientation" v-model="settingsForm.location_orientation" name="location_orientation">
						Orientation Location
					</b-form-checkbox>
				</b-col>
			</b-row>
		</b-card>
	</b-card-body>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex"
import ViewExtension from "@/views/ViewExtension"

export default ViewExtension.extend({
	name: "Settings",
	props: {
		settings: {
			required: false,
		},
		isDevice: {
			required: false,
		}
	},
	watch: {
		settings: {
			immediate: true,
			handler(val) {
				if (val) {
					this.settingsForm.set(val)
				}
			},
		},
	},
	computed: {},
	data() {
		return {
			settingsForm: new Form({
				id: null,
				consistency_token: null,
				navigation: false,
				temperature1: false,
				temperature2: false,
				temperature3: false,
				lock: false,
				orientation: false,
				location_lock: false,
				location_orientation: false,
			}),
			selected: null,
		}
	},
	computed: {},
	methods: {
		onSelect() {
			this.$emit("select", this.settingsForm.data())
		},
	},
	mounted(){
		if(this.isDevice){
			this.settingsForm.navigation = true
			this.settingsForm.temperature1 = true
			this.settingsForm.temperature2 = true
			this.settingsForm.temperature3 = true
			this.settingsForm.lock = true
			this.settingsForm.orientation = true
			this.settingsForm.location_lock = true
			this.settingsForm.location_orientation = true
		}
	}
})
</script>
